// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-don-john-apprehended-js": () => import("./../../../src/pages/don-john-apprehended.js" /* webpackChunkName: "component---src-pages-don-john-apprehended-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-local-private-eye-seeks-source-of-daming-picture-js": () => import("./../../../src/pages/local-private-eye-seeks-source-of-daming-picture.js" /* webpackChunkName: "component---src-pages-local-private-eye-seeks-source-of-daming-picture-js" */),
  "component---src-pages-mayor-welcomes-messina-troops-js": () => import("./../../../src/pages/mayor-welcomes-messina-troops.js" /* webpackChunkName: "component---src-pages-mayor-welcomes-messina-troops-js" */),
  "component---src-pages-men-seen-looking-in-windows-js": () => import("./../../../src/pages/men-seen-looking-in-windows.js" /* webpackChunkName: "component---src-pages-men-seen-looking-in-windows-js" */),
  "component---src-pages-obituaries-hero-js": () => import("./../../../src/pages/obituaries/hero.js" /* webpackChunkName: "component---src-pages-obituaries-hero-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-suspect-charged-in-scandal-to-dishonor-hero-js": () => import("./../../../src/pages/suspect-charged-in-scandal-to-dishonor-hero.js" /* webpackChunkName: "component---src-pages-suspect-charged-in-scandal-to-dishonor-hero-js" */),
  "component---src-pages-suspects-suspected-js": () => import("./../../../src/pages/suspects-suspected.js" /* webpackChunkName: "component---src-pages-suspects-suspected-js" */),
  "component---src-pages-troops-return-from-war-js": () => import("./../../../src/pages/troops-return-from-war.js" /* webpackChunkName: "component---src-pages-troops-return-from-war-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-templates-article-js": () => import("./../../../src/templates/article.js" /* webpackChunkName: "component---src-templates-article-js" */)
}

